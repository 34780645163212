import { api } from './config'
import schemas from './schemas'
import { Credentials, Field, QueryParams } from './types'
import { addNumDays } from './utils'

/*
 * Types
 */

interface FetchOptions {
  body?: string | File
  credentials?: 'include' | 'omit' | 'same-origin'
  headers?: {
    accept: string
    'content-type'?: string
  }
  method?:
    | 'CONNECT'
    | 'DELETE'
    | 'GET'
    | 'HEAD'
    | 'OPTIONS'
    | 'PATCH'
    | 'POST'
    | 'PUT'
    | 'TRACE'
  signal?: AbortSignal
}

interface QueryBody {
  limit?: number
  selector: {
    $not?: {}
    $or: Array<{ [key: string]: Object }>
    date?: { $gt?: string; $lt?: string }
    paid_on?: { $eq?: string; $gt?: string; $lt?: string }
    type: string
  }
  skip?: number
  sort: Array<{ [key: string]: string }>
}

/*
 * Attachments
 */

export const fetchAttachment = async (
  username: string,
  _id: string,
  filename: string
) => {
  const reqOpts: FetchOptions = {
    credentials: 'include'
  }

  const res = await window
    .fetch(`${api.host}/${username}/${_id}/${filename}`, {
      ...reqOpts
    })

  if (!res.ok) {
    const { error, reason } = await res.json()
    throw `${reason || 'Unknown'} (${error})`
  }

  return await res.blob()
}

export const removeAttachment = async (
  username: string,
  item: { _id: string; _rev: string },
  filename: string
) => {
  const reqOpts: FetchOptions = {
    credentials: 'include',
    method: 'DELETE'
  }

  const json = await window
    .fetch(`${api.host}/${username}/${item._id}/${filename}?rev=${item._rev}`, {
      ...reqOpts
    }).then(res => res.json())

  if (json.error) {
    throw `${json.reason || 'Unknown'} (${json.error})`
  }

  return json
}

export const saveAttachment = async (
  username: string,
  item: { _id: string; _rev: string },
  file: File
) => {
  const reqOpts: FetchOptions = {
    body: file,
    credentials: 'include',
    method: 'PUT'
  }

  const json = await window
    .fetch(
      `${api.host}/${username}/${item._id}/${file.name}?rev=${item._rev}`,
      {
        ...reqOpts
      }
    ).then(res => res.json())

  if (json.error) {
    throw `${json.reason || 'Unknown'} (${json.error})`
  }

  return json
}

export const saveAttachments = async (
  username: string,
  item: { _id: string; _rev: string },
  files: File[]
) => {
  let rev = item._rev

  for (let file of files) {
    const res = await saveAttachment(
      username,
      { _id: item._id, _rev: rev },
      file
    )
    rev = res.rev
  }

  return {
    ok: true,
    id: item._id,
    rev
  }
}

/*
 * Documents
 */

export const fetchAll = (username: string, type: keyof typeof schemas) =>
  async (
    queryParams: QueryParams,
    signal?: AbortSignal
  ) => {
    const _queryParams = {
      ...queryParams,
      limit: 100,
      skip: 0
    }

    const json = await fetchIndex(username, type)(_queryParams, signal)

    if (json.error) {
      throw `${json.reason || 'Unknown'} (${json.error})`
    }

    if (json.docs.length < _queryParams.limit + _queryParams.skip) {
      return json
    }

    while (json.docs.length === _queryParams.limit + _queryParams.skip) {
      _queryParams.skip += _queryParams.limit
      const res = await fetchIndex(username, type)(_queryParams, signal)
      if (res.docs.length === 0) break
      json.docs = [...json.docs, ...res.docs]
      json._relations = [...json._relations, ...res._relations]
    }

    return json
  }

export const fetchIndex = (
  username: string,
  type: keyof typeof schemas
) =>
  async ({
    dateFrom,
    dateTo,
    direction,
    filters,
    limit = 10,
    orderby,
    paidFrom,
    paidTo,
    search,
    skip = 0
  }: QueryParams, signal?: AbortSignal) => {
    const body: QueryBody = {
      limit,
      selector: { $or: [], type },
      skip,
      sort: [{ [orderby]: direction }]
    }
    const schema = schemas[type]

    const reqOpts: FetchOptions = {
      credentials: 'include',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      method: 'POST',
      signal
    }

    if (dateFrom) {
      body.selector.date = {
        ...(body.selector.date || {}),
        $gt: addNumDays(dateFrom, -1)
      }
    }

    if (dateTo) {
      body.selector.date = {
        ...(body.selector.date || {}),
        $lt: addNumDays(dateTo, 1)
      }
    }

    if (paidFrom) {
      body.selector.paid_on = {
        ...(body.selector.paid_on || {}),
        $gt: addNumDays(paidFrom, -1)
      }
    }

    if (paidTo) {
      body.selector.paid_on = {
        ...(body.selector.paid_on || {}),
        $lt: addNumDays(paidTo, 1)
      }
    }

    if (filters) {
      const { paid, ...selectors } = Object.fromEntries(
        Object.entries(filters).filter(([key, value]) => key && value)
      )

      body.selector = { ...body.selector, ...selectors }

      if (paid === 'notnull') {
        body.selector.$not = { paid_on: '' }
      }

      if (paid === 'null') {
        body.selector.paid_on = {
          ...(body.selector.paid_on || {}),
          $eq: ''
        }
      }
    }

    if (search) {
      schema.columnsSearch.forEach((searchkey: string) => {
        body.selector.$or.push({
          [searchkey]: { $regex: `(?i)${search}` }
        })
      })
    }

    const json = await window
      .fetch(`${api.host}/${username}/_find`, {
        ...reqOpts,
        body: JSON.stringify(body)
      })
      .then(res => res.json())

    if (json.error) {
      throw `${json.reason || 'Unknown'} (${json.error})`
    }

    const joins = [...schema.fields]
      .filter((field: Field) => field.service)
      .map(
        (field: Field): Set<string> =>
          new Set(json.docs.map((doc: any) => doc[field.name]))
      )

    const joinsJson = await Promise.all(
      joins
        .filter(ids => ids.size)
        .map(ids =>
          window
            .fetch(`${api.host}/${username}/_find`, {
              ...reqOpts,
              body: JSON.stringify({
                selector: {
                  $or: [...ids].map(id => ({ _id: id }))
                }
              })
            })
            .then(res => res.json())
        )
    )

    json._relations = joinsJson.map(({ docs }) => docs).flat()

    return json
  }

export const fetchItem = (
  username: string,
  type: keyof typeof schemas
) =>
  async (_id?: string, signal?: AbortSignal) => {
    if (!_id) {
      throw new Error('No _id provided!')
    }

    if (_id === 'new') {
      return Object.fromEntries(
        schemas[type].fields.map(field => [field.name, field.defaultValue])
      )
    }

    const schema = schemas[type]

    const reqOpts: FetchOptions = {
      credentials: 'include',
      headers: {
        accept: 'application/json'
      },
      signal
    }

    const doc = await window
      .fetch(`${api.host}/${username}/${_id}`, {
        ...reqOpts
      })
      .then(res => res.json())

    if (doc.error) {
      throw `${doc.reason || 'Unknown'} (${doc.error})`
    }

    const joins = [...schema.fields]
      .filter((field: Field) => field.service)
      .map((field: Field): Set<string> => new Set([doc[field.name]]))

    const joinsJson = await Promise.all(
      joins
        .filter(ids => ids.size)
        .map(ids =>
          window
            .fetch(`${api.host}/${username}/_find`, {
              ...reqOpts,
              body: JSON.stringify({
                selector: {
                  $or: [...ids].map(id => ({ _id: id }))
                }
              }),
              headers: {
                ...reqOpts.headers,
                'content-type': 'application/json'
              },
              method: 'POST'
            })
            .then(res => res.json())
        )
    )

    doc._relations = joinsJson.map(({ docs }) => docs).flat()

    return doc
  }

export const removeItem: any = async (
  username: string,
  data: { _id: string; _rev: string }
) => {
  const reqOpts: FetchOptions = {
    credentials: 'include',
    headers: {
      accept: 'application/json'
    },
    method: 'DELETE'
  }

  const json = await window
    .fetch(`${api.host}/${username}/${data._id}?rev=${data._rev}`, {
      ...reqOpts
    })
    .then(res => res.json())

  if (json.error) {
    throw `${json.reason || 'Unknown'} (${json.error})`
  }

  return json
}

export const saveItem: any = async (
  username: string,
  type: keyof typeof schemas,
  data: any
) => {
  delete data._relations

  const reqOpts: FetchOptions = {
    credentials: 'include',
    headers: {
      accept: 'application/json',
      'content-type': 'application/json'
    },
    method: data._id ? 'PUT' : 'POST'
  }

  let url = `${api.host}/${username}`

  if (data._id) {
    url += `/${data._id}`
  }

  const json = await window
    .fetch(url, {
      ...reqOpts,
      body: JSON.stringify({ ...data, type })
    })
    .then(res => res.json())

  if (json.error) {
    throw `${json.reason || 'Unknown'} (${json.error})`
  }

  return json
}

/*
 * Sessions
 */

export const fetchSession: any = async (signal: AbortSignal) => {
  const reqOpts: FetchOptions = {
    credentials: 'include',
    headers: {
      accept: 'application/json'
    },
    signal
  }

  const json = await fetch(`${api.host}/_session`, reqOpts).then(res =>
    res.json()
  )

  if (json.error) {
    throw `${json.reason || 'Unknown'} (${json.error})`
  }

  return json
}

export const removeSession = async () => {
  const reqOpts: FetchOptions = {
    credentials: 'include',
    headers: {
      accept: 'application/json'
    },
    method: 'DELETE'
  }

  const json = await fetch(`${api.host}/_session`, reqOpts).then(res =>
    res.json()
  )

  if (json.error) {
    throw `${json.reason || 'Unknown'} (${json.error})`
  }

  return json
}

export const saveSession: any = async (user: Credentials) => {
  const reqOpts: FetchOptions = {
    body: JSON.stringify({ name: user.name, password: user.password }),
    credentials: 'include',
    headers: {
      accept: 'application/json',
      'content-type': 'application/json'
    },
    method: 'POST'
  }

  const json = await fetch(`${api.host}/_session`, reqOpts).then(res =>
    res.json()
  )

  if (json.error) {
    throw `${json.reason || 'Unknown'} (${json.error})`
  }

  return json
}

/*
 * Status
 */

export const fetchStatus: () => Promise<boolean> = async () => {
  try {
    const res = await fetch(`${api.host}/_up`)
    return res.status === 200
  } catch (e) {
    return false
  }
}
