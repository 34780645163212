import { Field, QueryParams, Schema } from './types'

const getQueryStringPair = (key: string, value: string | object) => {
  if (value.constructor === Object) {
    return Object.entries(value).filter(([, v]) => v).map(([k, v]) =>
      `${key}[${k}]=${v}`
    ).join('&')
  }

  return `${key}=${value}`
}

const getValue = (value: FormDataEntryValue, field?: Field) => {
  switch (field?.type) {
    case 'checkbox':
      return value === 'true'

    case 'number':
      return typeof value === 'string' ? Number(value) ?? 0 : 0

    default:
      return value
  }
}

export const addNumDays = (dateStr: string = '1970-01-01', num: number = 1) =>
  new Date(new Date(dateStr).getTime() + (num * 86400000)).toISOString()
    .substring(0, 10)

export const addUnique = (arr = [], member: any) => [
  ...new Set([...arr, member])
]

export const debounce = (fn: any, delay: number = 250) => {
  let timeout: ReturnType<typeof setTimeout>
  return (...args: any[]) => {
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(fn, delay, ...args)
  }
}

export const docTitle = (str: string) => {
  const el = document.querySelector('meta[name="app-title"]')

  if (el) {
    document.title = el.getAttribute('content') + str
  }
}

export const getDisplayValue = (value: boolean | string, field: Field) => {
  if (typeof value === 'boolean') {
    return value ? 'ja' : 'nein'
  }

  if (field.type === 'date') {
    return value && new Date(value).toLocaleDateString()
  }

  if (field.type === 'number') {
    return new Intl.NumberFormat(navigator.language, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    }).format(Number(value))
  }

  if (field.type === 'select') {
    const option = field.options?.find(o =>
      (typeof o === 'string' ? o : o.value) === value
    )
    return typeof option === 'string' ? option : (option?.label || value)
  }

  return value?.replaceAll('\n', ', ')
}

export const getFilters = (searchParams: URLSearchParams) =>
  Object.fromEntries(
    [...searchParams].filter(([key]) => key?.startsWith('filters[')).map((
      [key, val]
    ) => [key.match(/.*\[(.*?)\]/)?.[1], val])
  )

export const getFormData = (form: HTMLFormElement, schema: Schema) =>
  Object.fromEntries([...new FormData(form)].map((
    [key, value]
  ) => [key, getValue(value, schema.fields.find(f => f.name === key))]))

export const getQueryString = (query: QueryParams) =>
  Object.entries(query)
    .filter(([, value]) => value)
    .map(([key, value]) => getQueryStringPair(key, value))
    .join('&')
