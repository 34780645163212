import clsx from 'clsx'
import { Notification } from '../types'
import IconX from './Icons/X'

interface NotificationProps {
  notifications: Array<Notification>
  removeNotification: (arg0: string) => void
}

const getColor = (type: string) => {
  switch (type) {
    case 'error':
    case 'session-error':
      return 'bg-red-500'
    case 'warning':
      return 'bg-yellow-600'
    default:
      return 'bg-indigo-500'
  }
}

const Notifications = ({
  notifications,
  removeNotification
}: NotificationProps) => {
  return (
    <div className='fixed right-0 top-0 w-96'>
      {notifications.map((item: Notification) => (
        <div
          className={clsx(
            'm-2 p-3 relative rounded shadow-lg text-white',
            getColor(item.type)
          )}
          key={item.id}
        >
          {item.type !== 'session-error' && (
            <button
              className='absolute right-2 top-2'
              onClick={() => removeNotification(item.id)}
              type='button'
            >
              <IconX />
            </button>
          )}

          <p className='font-bold'>{item.title}</p>

          <div className='text-sm'>
            {item.body}
          </div>
        </div>
      ))}
    </div>
  )
}

export default Notifications
