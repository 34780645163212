import clsx from 'clsx'
import { FormEvent, MouseEvent, useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { queryClient } from '../.'
import {
  fetchItem,
  removeAttachment,
  removeItem,
  saveAttachments,
  saveItem
} from '../api'
import Attachments from '../components/Attachments'
import Button from '../components/Button'
import H1 from '../components/H1'
import IconLeft from '../components/Icons/Left'
import IconPencilSquare from '../components/Icons/PencilSquare'
import IconPlusSquare from '../components/Icons/PlusSquare'
import IconTrash from '../components/Icons/Trash'
import Input from '../components/Input'
import Label from '../components/Label'
import Select from '../components/Select'
import SelectFromService from '../components/SelectFromService'
import Textarea from '../components/Textarea'
import { documents as schema } from '../schemas'
import { Document as DocumentType, NotificationNew } from '../types'
import { docTitle, getFormData } from '../utils'

interface DocumentProps {
  addNotification: (notification: NotificationNew) => void
  handleError: (error: unknown) => void
  username: string
}

const Document = (
  { addNotification, handleError, username }: DocumentProps
) => {
  const navigate = useNavigate()
  const { id } = useParams()

  const { data, error, isLoading } = useQuery(
    ['documents', id],
    ({ signal }) => fetchItem(username, 'documents')(id, signal),
    { onError: handleError }
  )

  const remove = useMutation(data => removeItem(username, data))
  const upsert = useMutation(data => saveItem(username, 'documents', data))

  const [uploads, setUploads] = useState<File[]>([])

  const title = `${schema.label.singular} ${
    id === 'new' ? 'erstellen' : 'bearbeiten'
  }`

  const addFilesizeNotification = () =>
    addNotification({
      body:
        'Bitte laden Sie nur Dateien mit einer Größe von bis zu 10 MB hoch.',
      title: 'Date zu groß',
      type: 'warning'
    })

  const handleAddUploads = (files: File[]) => {
    setUploads(prevState => {
      const nextState = [...prevState]
      files.forEach(file => {
        const i = nextState.findIndex(f => f.name === file.name)
        if (i > -1) {
          nextState[i] = file
        } else {
          nextState.push(file)
        }
      })
      return nextState
    })
  }

  const handleDelete = () => {
    if (window.confirm(`${data.no} wirklich löschen?`)) {
      remove.mutate(data, {
        onError: handleError,
        onSuccess: () => navigate(-1)
      })
    }
  }

  const handleRemoveAttachment = (e: MouseEvent<HTMLButtonElement>) => {
    const svg = e.currentTarget.parentElement?.children[1].children[0]
      .children[0].children[0] as SVGElement
    const fileName = e.currentTarget.name

    if (id === 'new') {
      return setUploads(prevState =>
        prevState.filter(file => file.name !== fileName)
      )
    }

    svg.classList.remove('text-indigo-600')
    svg.classList.add('text-slate-200', 'pointer-events-none')

    removeAttachment(username, data, fileName).then(res =>
      fetchItem(username, 'documents')(res.id)
    ).then((item: DocumentType) => {
      queryClient.setQueryData(['documents', id], {
        ...data,
        _attachments: item._attachments,
        _rev: item._rev
      })
    }).catch((error: Error) => handleError(error.toString()))
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const changes = getFormData(e.currentTarget, schema)
    upsert.mutate(
      { ...data, ...changes },
      {
        onError: handleError,
        onSuccess: (res: any) =>
          saveAttachments(username, { _id: res.id, _rev: res.rev }, uploads)
            .then(
              () => navigate(-1),
              error => {
                handleError(error)
                navigate(`/documents/${res.id}`)
              }
            )
      }
    )
  }

  useEffect(() => {
    if (id === 'new' || !data?._id || uploads.length === 0) {
      return
    }

    saveAttachments(username, data, uploads).then(res =>
      fetchItem(username, 'documents')(res.id)
    ).then((item: DocumentType) => {
      queryClient.setQueryData(['documents', id], {
        ...data,
        _attachments: item._attachments,
        _rev: item._rev
      })
      setUploads([])
    }).catch((error: Error) => handleError(error.toString()))
  }, [data, uploads])

  useEffect(() => {
    docTitle(title)
  }, [])

  return (
    <>
      <div className='flex items-center gap-2'>
        <button
          className='pb-4 text-indigo-600'
          onClick={() => navigate(-1)}
          type='button'
        >
          <IconLeft height='22' width='22' />
        </button>

        <H1>{title}</H1>
      </div>

      <form
        className={clsx(
          (isLoading || remove.isLoading || upsert.isLoading) &&
            'opacity-50 pointer-events-none'
        )}
        onSubmit={handleSubmit}
      >
        <div className='flex flex-wrap -m-2'>
          {schema.fields.map((
            { defaultValue, label, service, type, ...props }
          ) => (
            <div className='md:w-1/2 p-2 w-full' key={props.name}>
              <Label>
                {label}

                {props.name === 'contacts_id' && (
                  <>
                    <Link
                      className='ml-2'
                      title='Neuer Kontakt'
                      to='/contacts/new'
                    >
                      <IconPlusSquare
                        className='inline'
                        height='14'
                        width='14'
                      />
                    </Link>

                    <Link
                      className='ml-2'
                      title='Kontakt bearbeiten'
                      to={`/contacts/${data?.[props.name]}`}
                    >
                      <IconPencilSquare className='inline' />
                    </Link>
                  </>
                )}

                {type === 'select'
                  ? (
                    <Select
                      defaultValue={data?.[props.name]}
                      key={data?.[props.name]}
                      {...props}
                    />
                  )
                  : type === 'service' && service
                  ? (
                    <SelectFromService
                      defaultValue={data?.[props.name]}
                      handleError={handleError}
                      key={data?.[props.name]}
                      service={service}
                      username={username}
                      {...props}
                    />
                  )
                  : type === 'textarea'
                  ? (
                    <Textarea
                      defaultValue={data?.[props.name]}
                      key={data?.[props.name]}
                      {...props}
                    />
                  )
                  : (
                    <Input
                      defaultValue={data?.[props.name]}
                      key={data?.[props.name]}
                      type={type}
                      {...props}
                    />
                  )}
              </Label>
            </div>
          ))}
        </div>

        <div className='mt-4'>
          <Label>Dateianhänge</Label>

          <Attachments
            addFilesizeNotification={addFilesizeNotification}
            handleAddUploads={handleAddUploads}
            handleError={handleError}
            handleRemove={handleRemoveAttachment}
            item={data}
            uploads={uploads}
            username={username}
          />
        </div>

        <div className='flex justify-end pt-6'>
          <div className='flex gap-4'>
            {id !== 'new' && !error && (
              <div>
                <Button isWhite onClick={handleDelete} type='button'>
                  <div className='flex gap-1 items-center'>
                    <div>
                      <IconTrash className='block fill-red-500' />
                    </div>

                    <div>Löschen</div>
                  </div>
                </Button>
              </div>
            )}

            <div>
              <Button color='primary' disabled={!!error} type='submit'>
                Speichern
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default Document
