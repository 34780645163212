import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import CenterBox from '../components/CenterBox'
import Code from '../components/Code'
import Button from '../components/Button'
import H1 from '../components/H1'
import P from '../components/P'
import { docTitle } from '../utils'

const NotFound = () => {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    docTitle('Nicht gefunden!')
  }, [])

  return (
    <div className='flex min-h-full justify-center'>
      <CenterBox>
        <H1>Inhalt nicht gefunden:</H1>

        <P>
          <Code>{location.pathname}</Code>
        </P>

        <div className='mt-2'>
          <Button onClick={() => navigate(-1)} type='button'>
            Zurück
          </Button>
        </div>
      </CenterBox>
    </div>
  )
}

export default NotFound
