import { TableHTMLAttributes } from 'react'

const Table = (props: TableHTMLAttributes<HTMLTableElement>) => (
  <table
    className='bg-white border-collapse min-w-full overflow-hidden rounded whitespace-nowrap shadow'
    {...props}
  />
)

export default Table
