import React from 'react'
import ReactDOM from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { fetchSession } from './api'
import App from './App'
import { Session } from './types'
import Error from './views/Error'

export const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

fetchSession().then(
  (session: Session) =>
    root.render(
      <React.StrictMode>
        <QueryClientProvider client={queryClient}>
          <App initialSession={session} />
        </QueryClientProvider>
      </React.StrictMode>
    ),
  (error: Error) =>
    root.render(
      <React.StrictMode>
        <Error error={error} />
      </React.StrictMode>
    )
)
