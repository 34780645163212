import clsx from 'clsx'
import {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useState
} from 'react'
import { useQuery } from 'react-query'
import { Link, useSearchParams } from 'react-router-dom'
import { fetchIndex } from '../api'
import Button from '../components/Button'
import H1 from '../components/H1'
import IconPlus from '../components/Icons/Plus'
import IconSortDown from '../components/Icons/SortDown'
import IconSortUp from '../components/Icons/SortUp'
import IconXCircle from '../components/Icons/XCircle'
import Input from '../components/Input'
import Label from '../components/Label'
import Loading from '../components/Loading'
import Pagination from '../components/Pagination'
import Select from '../components/Select'
import SelectFromService from '../components/SelectFromService'
import Table from '../components/Table'
import Td from '../components/Td'
import Th from '../components/Th'
import { documents as schema } from '../schemas'
import { Contact, Document, Filters, QueryParams } from '../types'
import {
  debounce,
  docTitle,
  getDisplayValue,
  getFilters,
  getQueryString
} from '../utils'

interface DocumentsProps {
  handleError: (error: unknown) => void
  username: string
}

const getContactLabel = (item: Document, relations?: Array<Contact>) => {
  const relation = relations?.find(r => {
    return r._id === item.contacts_id
  })
  return relation?.name || item.contacts_id
}

const Documents = ({ handleError, username }: DocumentsProps) => {
  const title = schema.label.plural

  const [searchParams, setSearchParams] = useSearchParams()

  const [dateFrom, setDateFrom] = useState(searchParams.get('dateFrom') || '')
  const [dateTo, setDateTo] = useState(searchParams.get('dateTo') || '')

  const [paidFrom, setPaidFrom] = useState(searchParams.get('paidFrom') || '')
  const [paidTo, setPaidTo] = useState(searchParams.get('paidTo') || '')

  const [query, setQuery] = useState<QueryParams>({
    dateFrom,
    dateTo,
    direction: searchParams.get('direction') || schema.sort.direction,
    filters: getFilters(searchParams),
    limit: Number(searchParams.get('limit') || 10),
    orderby: searchParams.get('orderby') || schema.sort.orderby,
    paidFrom,
    paidTo,
    search: searchParams.get('search') || '',
    skip: Number(searchParams.get('skip') || 0)
  })

  const { data, error, isLoading } = useQuery(
    ['documents', query],
    ({ signal }) => fetchIndex(username, 'documents')(query, signal),
    {
      onError: handleError,
      retry: 0
    }
  )

  const syncDateFrom = useCallback(
    debounce(
      (dateFrom: string) =>
        setQuery(prevState => ({
          ...prevState,
          dateFrom
        }))
    ),
    []
  )

  const syncDateTo = useCallback(
    debounce(
      (dateTo: string) =>
        setQuery(prevState => ({
          ...prevState,
          dateTo
        }))
    ),
    []
  )

  const syncPaidFrom = useCallback(
    debounce(
      (paidFrom: string) =>
        setQuery(prevState => ({
          ...prevState,
          paidFrom
        }))
    ),
    []
  )

  const syncPaidTo = useCallback(
    debounce(
      (paidTo: string) =>
        setQuery(prevState => ({
          ...prevState,
          paidTo
        }))
    ),
    []
  )

  const clearFilters = () => {
    setDateFrom('')
    setDateTo('')
    setPaidFrom('')
    setPaidTo('')
    setQuery(prevState => ({
      ...prevState,
      dateFrom: '',
      dateTo: '',
      filters: {
        ...prevState.filters,
        account: '',
        contacts_id: '',
        paid: ''
      },
      paidFrom: '',
      paidTo: '',
      search: ''
    }))
  }

  const handleDateFrom = (
    { target: { value } }: ChangeEvent<HTMLInputElement>
  ) => {
    setDateFrom(value)
    syncDateFrom(value)
  }

  const handleDateTo = (
    { target: { value } }: ChangeEvent<HTMLInputElement>
  ) => {
    setDateTo(value)
    syncDateTo(value)
  }

  const handlePaidFrom = (
    { target: { value } }: ChangeEvent<HTMLInputElement>
  ) => {
    setPaidFrom(value)
    syncPaidFrom(value)
  }

  const handlePaidTo = (
    { target: { value } }: ChangeEvent<HTMLInputElement>
  ) => {
    setPaidTo(value)
    syncPaidTo(value)
  }

  const handleFilters = (key: keyof Filters) =>
    ({ target: { value } }: ChangeEvent<HTMLSelectElement>) =>
      setQuery(prevState => {
        const nextState = {
          ...prevState,
          filters: {
            ...(prevState.filters || {})
          }
        }

        if (value) {
          nextState.filters[key] = value
        } else {
          delete nextState.filters[key]
        }

        return nextState
      })

  const handleSearch = ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
    setQuery(prevState => ({
      ...prevState,
      search: value,
      skip: 0
    }))

  const handleSort = ({
    currentTarget: { name }
  }: MouseEvent<HTMLButtonElement>) =>
    setQuery(prevState => {
      const nextState = { ...prevState, skip: 0 }
      nextState.direction = 'asc'
      nextState.orderby = name

      if (prevState.orderby === name && prevState.direction === 'asc') {
        nextState.direction = 'desc'
      }

      if (prevState.orderby === name && prevState.direction === 'desc') {
        nextState.direction = 'asc'
      }

      return nextState
    })

  useEffect(() => {
    setSearchParams(getQueryString(query))
  }, [query])

  useEffect(() => {
    docTitle(title)
  }, [])

  const filterActive = query.search || query.filters?.contacts_id ||
    query.filters?.account || query.filters?.paid ||
    query.dateFrom || query.dateTo || query.paidFrom ||
    query.paidTo

  return (
    <>
      <div className='flex items-center gap-2'>
        <H1>{title}</H1>

        <div className='text-indigo-500 pb-4'>
          <Link to='/documents/new'>
            <IconPlus height='22' width='22' />
          </Link>
        </div>
      </div>

      <details className='mb-4'>
        <summary className='cursor-pointer text-slate-600'>
          Suchen & Filtern{filterActive && (
            <span className='font-bold text-slate-500'>*</span>
          )}
        </summary>

        <div className='items-end grid gap-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mb-3 text-slate-600'>
          <Label size='sm'>
            Suchen
            <Input
              isActive={!!query.search}
              onInput={handleSearch}
              placeholder='Suchbegriff'
              type='search'
              value={query.search}
            />
          </Label>

          <Label size='sm'>
            Kontakte
            <SelectFromService
              handleError={handleError}
              isActive={!!query.filters?.contacts_id}
              isClearable
              onChange={handleFilters('contacts_id')}
              placeholder='(Alle Kontakte)'
              service='contacts'
              username={username}
              value={query.filters?.contacts_id}
            />
          </Label>

          <Label size='sm'>
            Einnahme / Ausgabe
            <Select
              isActive={!!query.filters?.account}
              isClearable
              onChange={handleFilters('account')}
              options={[{ label: 'Einnahmen', value: 'revenue' }, {
                label: 'Ausgaben',
                value: 'expense'
              }]}
              placeholder='(Alle Dokumente)'
              value={query.filters?.account}
            />
          </Label>

          <Label size='sm'>
            Zahlstatus
            <Select
              isActive={!!query.filters?.paid}
              isClearable
              onChange={handleFilters('paid')}
              options={[{ label: 'bezahlt', value: 'notnull' }, {
                label: 'unbezahlt',
                value: 'null'
              }]}
              placeholder='(Alle Dokumente)'
              value={query.filters?.paid}
            />
          </Label>

          <Label size='sm'>
            Datum von
            <Input
              isActive={!!dateFrom}
              max={dateTo}
              onChange={handleDateFrom}
              type='date'
              value={dateFrom}
            />
          </Label>

          <Label size='sm'>
            Datum bis
            <Input
              isActive={!!dateTo}
              min={dateFrom}
              onChange={handleDateTo}
              type='date'
              value={dateTo}
            />
          </Label>

          <Label size='sm'>
            Gezahlt von
            <Input
              isActive={!!paidFrom}
              max={paidTo}
              onChange={handlePaidFrom}
              type='date'
              value={paidFrom}
            />
          </Label>

          <Label size='sm'>
            Gezahlt bis
            <Input
              isActive={!!paidTo}
              min={paidFrom}
              onChange={handlePaidTo}
              type='date'
              value={paidTo}
            />
          </Label>
        </div>

        <div>
          <Button
            disabled={!filterActive}
            isWhite
            onClick={clearFilters}
            size='sm'
            type='button'
          >
            <IconXCircle className='text-slate-500' />
          </Button>
        </div>
      </details>

      <div
        className={clsx(
          'overflow-auto mb-3 pb-1 transition-opacity w-full',
          isLoading && 'opacity-50 pointer-events-none'
        )}
      >
        <Table>
          <thead>
            <tr>
              {schema.columnsDisplay.map(column => {
                const field = schema.fields.find(field => field.name === column)

                return (
                  <Th key={column}>
                    {field?.type === 'service'
                      ? <>{field?.label || column}</>
                      : (
                        <button
                          className='font-bold'
                          name={column}
                          onClick={handleSort}
                          type='button'
                        >
                          {field?.label ?? column}
                          {query.orderby === column &&
                            query.direction === 'asc' && (
                            <IconSortUp className='inline ml-1' />
                          )}
                          {query.orderby === column &&
                            query.direction === 'desc' && (
                            <IconSortDown className='inline ml-1' />
                          )}
                        </button>
                      )}
                  </Th>
                )
              })}
            </tr>
          </thead>

          {data?.docs.length === 0 || error || isLoading
            ? (
              <tbody>
                <tr>
                  <Td colSpan={schema.columnsDisplay.length}>
                    <span className='opacity-75'>
                      {isLoading ? <Loading /> : query.skip === 0
                        ? (
                          'Keine Dokumente gefunden'
                        )
                        : (
                          'Keine weiteren Dokumente gefunden'
                        )}
                    </span>
                  </Td>
                </tr>
              </tbody>
            )
            : (
              <tbody>
                {data?.docs.map((item: any) => (
                  <tr key={item._id}>
                    {schema.columnsDisplay.map(column => {
                      const field = schema.fields.find(
                        field => field.name === column
                      )
                      return (
                        <Td key={`${column}-${item._id}`}>
                          {field && (
                            <Link to={`/documents/${item._id}`}>
                              {field.service === 'contacts'
                                ? getContactLabel(item, data._relations)
                                : getDisplayValue(item[column], field)}
                            </Link>
                          )}
                        </Td>
                      )
                    })}
                  </tr>
                ))}
              </tbody>
            )}
        </Table>
      </div>

      {data?.docs && (
        <Pagination items={data.docs} query={query} setQuery={setQuery} />
      )}
    </>
  )
}

export default Documents
