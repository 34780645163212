import { ButtonHTMLAttributes } from 'react'

type Color = 'danger' | 'primary' | 'warning'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: Color
  isWhite?: boolean
  size?: 'sm'
}

const getColors = (color?: Color, isWhite?: boolean) => {
  if (isWhite) {
    switch (color) {
      case 'primary':
        return 'bg-white border border-slate-200 focus:outline-indigo-500 text-indigo-600'
      case 'warning':
        return 'bg-white border border-slate-200 focus:outline-yellow-500 text-yellow-600'
      case 'danger':
        return 'bg-white border border-slate-200 focus:outline-red-500 text-red-600'
      default:
        return 'bg-white border border-slate-200 focus:outline-slate-500 text-slate-600'
    }
  }

  switch (color) {
    case 'primary':
      return 'bg-indigo-600 border border-slate-200 focus:outline-indigo-500 hover:bg-indigo-500 text-white '
    case 'warning':
      return 'bg-yellow-600 border border-slate-200 focus:outline-yellow-500 hover:bg-yellow-500 text-white '
    case 'danger':
      return 'bg-red-600 border border-slate-200 focus:outline-red-500 hover:bg-red-500 text-white '
    default:
      return 'bg-slate-500 border border-slate-200 focus:outline-slate-600 hover:bg-slate-600 text-white '
  }
}

const Button = ({ color, isWhite, size, ...extraProps }: ButtonProps) => {
  let classes = getColors(color, isWhite)

  if (size === 'sm') {
    classes += 'px-2 py-1 text-sm'
  }

  return (
    <button
      className={`disabled:opacity-75 hover:shadow-none px-4 py-2 rounded shadow transition-all ${classes}`}
      {...extraProps}
    />
  )
}

export default Button
