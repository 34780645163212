interface H1Props {
  children: React.ReactNode
}

const H1 = ({ children, ...props }: H1Props) => (
  <h1 className='text-2xl text-indigo-600 pb-4' {...props}>
    {children}
  </h1>
)

export default H1
