interface PProps {
  children: React.ReactNode
}

const P = ({ children, ...props }: PProps) => (
  <p className='pb-2' {...props}>
    {children}
  </p>
)

export default P
