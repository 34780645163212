import { MouseEvent } from 'react'
import Button from './Button'
import IconLeft from './Icons/Left'
import IconRight from './Icons/Right'
import { QueryParams } from '../types'

interface PaginationProps {
  items: object[]
  query: QueryParams
  setQuery: Function
}

const Pagination = ({ items, query, setQuery }: PaginationProps) => {
  const handlePagination = ({
    currentTarget: { name },
  }: MouseEvent<HTMLButtonElement>) =>
    setQuery((prevState: QueryParams) => {
      const nextState = { ...prevState }

      if (name === 'next') {
        nextState.skip += nextState.limit
      }

      if (name === 'previous') {
        nextState.skip -= nextState.limit
      }

      return nextState
    })

  return (
    <div className='items-center flex gap-2 justify-center'>
      <Button
        disabled={query.skip === 0}
        isWhite
        name='previous'
        onClick={handlePagination}
      >
        <IconLeft className='pointer-events-none' />
      </Button>

      <div className='text-slate-500'>
        Seite: {Math.ceil(query.skip / query.limit) + 1}
      </div>

      <Button
        disabled={items.length < query.limit}
        isWhite
        name='next'
        onClick={handlePagination}
      >
        <IconRight className='pointer-events-none' />
      </Button>
    </div>
  )
}

export default Pagination
