export const contacts = {
  columnsDisplay: ['name', 'email', 'phone', 'address'],
  columnsSearch: ['name', 'email', 'phone', 'address'],
  fields: [
    {
      defaultValue: '',
      label: 'Name',
      name: 'name',
      type: 'text',
      required: true
    },
    {
      defaultValue: '',
      label: 'E-Mail',
      name: 'email',
      type: 'email'
    },
    {
      defaultValue: '',
      label: 'Telefonnummer',
      name: 'phone',
      type: 'tel'
    },
    {
      defaultValue: '',
      label: 'Umsatzsteuer-ID',
      name: 'ustid',
      type: 'text'
    },
    {
      defaultValue: '',
      label: 'Adresse',
      name: 'address',
      type: 'textarea'
    }
  ],
  getLabel: (item: any) => item.name,
  label: {
    singular: 'Kontakt',
    plural: 'Kontakte'
  },
  sort: {
    direction: 'asc',
    orderby: 'name'
  }
}

export const documents = {
  columnsDisplay: ['contacts_id', 'account', 'date', 'no', 'amount', 'paid_on'],
  columnsSearch: ['no'],
  fields: [
    {
      defaultValue: '',
      label: 'Kontakt',
      name: 'contacts_id',
      required: true,
      service: 'contacts',
      type: 'service'
    },
    {
      defaultValue: 'expense',
      label: 'Einnahme / Ausgabe',
      name: 'account',
      options: [{ label: 'Einnahme', value: 'revenue' }, {
        label: 'Ausgabe',
        value: 'expense'
      }],
      required: true,
      type: 'select'
    },
    {
      defaultValue: new Date().toISOString().replace(/T.*/, ''),
      label: 'Datum',
      name: 'date',
      required: true,
      type: 'date'
    },
    {
      defaultValue: '',
      label: 'Rechnungsnummer',
      name: 'no',
      required: true,
      type: 'text'
    },
    {
      defaultValue: 0,
      label: 'Betrag',
      name: 'amount',
      required: true,
      step: '0.01',
      type: 'number'
    },
    {
      defaultValue: '19%',
      label: 'Steuersatz',
      name: 'vat',
      options: ['0%', '7%', '19%'],
      required: true,
      type: 'select'
    },
    {
      defaultValue: '',
      label: 'Gezahlt am',
      name: 'paid_on',
      type: 'date'
    },
    {
      defaultValue: '',
      label: 'Notiz',
      name: 'note',
      type: 'textarea'
    }
  ],
  getLabel: (item: { contacts_id: string; no: string }) =>
    `${item.no} (${item.contacts_id})`,
  label: {
    singular: 'Dokument',
    plural: 'Dokumente'
  },
  searchColumns: ['no'],
  sort: {
    direction: 'desc',
    orderby: 'date'
  }
}

const schemas = { contacts, documents }

export default schemas
