interface CodeProps {
  children: React.ReactNode
}

const Code = ({ children, ...props }: CodeProps) => (
  <code className='break-words text-slate-500' {...props}>
    {children}
  </code>
)

export default Code
