import clsx from 'clsx'
import { ChangeEvent, MouseEvent, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { Link, useSearchParams } from 'react-router-dom'
import { fetchIndex } from '../api'
import H1 from '../components/H1'
import IconPlus from '../components/Icons/Plus'
import IconSortDown from '../components/Icons/SortDown'
import IconSortUp from '../components/Icons/SortUp'
import Input from '../components/Input'
import Loading from '../components/Loading'
import Pagination from '../components/Pagination'
import Table from '../components/Table'
import Td from '../components/Td'
import Th from '../components/Th'
import { contacts as schema } from '../schemas'
import { QueryParams } from '../types'
import { docTitle, getDisplayValue, getQueryString } from '../utils'

interface ContactsProps {
  handleError: (error: unknown) => void
  username: string
}

const Contacts = ({ handleError, username }: ContactsProps) => {
  const title = schema.label.plural

  const [searchParams, setSearchParams] = useSearchParams()

  const [query, setQuery] = useState<QueryParams>({
    direction: searchParams.get('direction') || schema.sort.direction,
    limit: Number(searchParams.get('limit') || 10),
    orderby: searchParams.get('orderby') || schema.sort.orderby,
    search: searchParams.get('search') || '',
    skip: Number(searchParams.get('skip') || 0)
  })

  const { data, error, isLoading } = useQuery(
    ['contacts', query],
    ({ signal }) => fetchIndex(username, 'contacts')(query, signal),
    {
      onError: handleError,
      retry: 0
    }
  )

  const handleSearch = ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
    setQuery(prevState => ({
      ...prevState,
      search: value,
      skip: 0
    }))

  const handleSort = ({
    currentTarget: { name }
  }: MouseEvent<HTMLButtonElement>) =>
    setQuery(prevState => {
      const nextState = { ...prevState, skip: 0 }
      nextState.direction = 'asc'
      nextState.orderby = name

      if (prevState.orderby === name && prevState.direction === 'asc') {
        nextState.direction = 'desc'
      }

      if (prevState.orderby === name && prevState.direction === 'desc') {
        nextState.direction = 'asc'
      }

      return nextState
    })

  useEffect(() => {
    setSearchParams(getQueryString(query))
  }, [query])

  useEffect(() => {
    docTitle(title)
  }, [])

  return (
    <>
      <div className='flex items-center gap-2'>
        <H1>{title}</H1>

        <div className='text-indigo-500 pb-4'>
          <Link to='/contacts/new'>
            <IconPlus height='22' width='22' />
          </Link>
        </div>
      </div>

      <details className='mb-4'>
        <summary className='cursor-pointer text-slate-600'>
          Suchen{query.search && (
            <span className='font-bold text-slate-500'>*</span>
          )}
        </summary>

        <div>
          <Input
            onInput={handleSearch}
            placeholder='Suchbegriff'
            value={query.search}
            type='search'
          />
        </div>
      </details>

      <div
        className={clsx(
          'overflow-auto mb-3 pb-1 transition-opacity w-full',
          isLoading && 'opacity-50 pointer-events-none'
        )}
      >
        <Table>
          <thead>
            <tr>
              {schema.columnsDisplay.map(column => {
                const field = schema.fields.find(field => field.name === column)

                return (
                  <Th key={column}>
                    <button
                      className='font-bold'
                      name={column}
                      onClick={handleSort}
                      type='button'
                    >
                      {field?.label ?? column}
                      {query.orderby === column &&
                        query.direction === 'asc' && (
                        <IconSortUp className='inline ml-1' />
                      )}
                      {query.orderby === column &&
                        query.direction === 'desc' && (
                        <IconSortDown className='inline ml-1' />
                      )}
                    </button>
                  </Th>
                )
              })}
            </tr>
          </thead>

          {data?.docs.length === 0 || error || isLoading
            ? (
              <tbody>
                <tr>
                  <Td colSpan={schema.columnsDisplay.length}>
                    <span className='opacity-75'>
                      {isLoading ? <Loading /> : query.skip === 0
                        ? (
                          'Keine Kontakte gefunden'
                        )
                        : (
                          'Keine weiteren Kontakte gefunden'
                        )}
                    </span>
                  </Td>
                </tr>
              </tbody>
            )
            : (
              <tbody>
                {data?.docs.map((item: any) => (
                  <tr key={item._id}>
                    {schema.columnsDisplay.map(column => {
                      const field = schema.fields.find(
                        field => field.name === column
                      )
                      return (
                        <Td key={`${column}-${item._id}`}>
                          {field && (
                            <Link to={`/contacts/${item._id}`}>
                              {getDisplayValue(item[column], field)}
                            </Link>
                          )}
                        </Td>
                      )
                    })}
                  </tr>
                ))}
              </tbody>
            )}
        </Table>
      </div>

      {data?.docs && (
        <Pagination items={data.docs} query={query} setQuery={setQuery} />
      )}
    </>
  )
}

export default Contacts
