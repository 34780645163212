import { forwardRef, FocusEvent, InputHTMLAttributes, } from 'react'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  defaultValue?: any
  isActive?: boolean
}

export const classes =
  'block border border-slate-200 focus:border-indigo-600 focus:outline-none focus:shadow-none hover:shadow-none p-2 rounded shadow transition-shadow w-full'

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ isActive, ...props }, ref) => {
    if (props.type === 'checkbox' || props.type === 'radio') {
      return <input className='align-sub block h-6 w-6' ref={ref} {...props} />
    }

    const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
      if (e.target.type === 'number') {
        e.target.select()
      }
    }

    return (
      <input
        onFocus={handleFocus}
        className={`${classes}${isActive ? ' border-slate-500' : ''}`}
        ref={ref}
        {...props}
      />
    )
  }
)

export default Input
