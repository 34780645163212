import { useEffect } from 'react'
import Button from '../components/Button'
import CenterBox from '../components/CenterBox'
import Code from '../components/Code'
import H1 from '../components/H1'
import P from '../components/P'
import { docTitle } from '../utils'

interface ErrorProps {
  error: any
  reset?: () => void
  resetLabel?: string
}

const Error = ({
  error,
  reset = () => window.location.reload(),
  resetLabel = 'App neu laden',
}: ErrorProps) => {
  console.error(error)

  useEffect(() => {
    docTitle('Fehler!')
  }, [])

  return (
    <div className='flex items-center justify-center min-h-screen'>
      <CenterBox>
        <H1>Es ist ein Fehler aufgetreten:</H1>

        <P>
          <Code>{error.message ?? error.toString() ?? error}</Code>
        </P>

        <div className='mt-2'>
          <Button onClick={reset} type='button'>
            {resetLabel}
          </Button>
        </div>
      </CenterBox>
    </div>
  )
}

export default Error
