import { FormEvent, useEffect } from 'react'
import Button from '../components/Button'
import CenterBox from '../components/CenterBox'
import H1 from '../components/H1'
import Input from '../components/Input'
import Label from '../components/Label'
import Logo from '../components/Logo'
import { docTitle } from '../utils'

interface LoginProps {
  handleLogin: (e: FormEvent<HTMLFormElement>) => void
  isLoading: boolean
}

const Login = ({ handleLogin, isLoading }: LoginProps) => {
  useEffect(() => {
    docTitle('Login')
  }, [])

  return (
    <div className='flex items-center justify-center min-h-screen'>
      <CenterBox>
        <form
          className={isLoading ? 'opacity-50 pointer-events-none' : undefined}
          onSubmit={handleLogin}
        >
          <H1>
            <Logo />
          </H1>

          <div className='mb-2'>
            <Label>
              Name:
              <Input autoComplete='username' name='name' required />
            </Label>
          </div>

          <div className='mb-6'>
            <Label>
              Passwort:
              <Input
                autoComplete='current-password'
                name='password'
                required
                type='password'
              />
            </Label>
          </div>

          <Button color='primary' type='submit'>
            Login
          </Button>
        </form>
      </CenterBox>
    </div>
  )
}

export default Login
