interface LabelProps {
  children: React.ReactNode
  size?: 'sm' | 'base' | 'lg' | 'xl' | '2xl'
}

const Label = ({ children, size, ...props }: LabelProps) => (
  <label className='block text-slate-600 w-full' {...props}>
    <span className={`text-${size}`}>
      {children}
    </span>
  </label>
)

export default Label
