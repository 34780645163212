import { nanoid } from 'nanoid'
import { FormEvent, useEffect, useRef, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import {
  BrowserRouter,
  Navigate,
  NavLink,
  Route,
  Routes
} from 'react-router-dom'
import pkg from '../package.json'
import { fetchSession, fetchStatus, removeSession, saveSession } from './api'
import Logo from './components/Logo'
import Notifications from './components/Notifications'
import './styles.css'
import { Credentials, Notification, NotificationNew, Session } from './types'
import Contact from './views/Contact'
import Contacts from './views/Contacts'
import Document from './views/Document'
import Documents from './views/Documents'
import Login from './views/Login'
import NotFound from './views/NotFound'

interface AppProps {
  initialSession: Session
}

const menuItemClasses =
  'border-b-4 border-transparent font-bold hover:opacity-75 mx-2 -mb-1 transition-all uppercase text-sm'

const App = ({ initialSession }: AppProps) => {
  const offlineRef = useRef<string>()
  const [notifications, setNotifications] = useState<Array<Notification>>([])

  const addNotification = (notification: NotificationNew) => {
    const id = nanoid()

    setNotifications(prevState => [
      ...prevState,
      { ...notification, id }
    ])

    if (
      !notification.type.endsWith('error') &&
      !notification.type.endsWith('warning')
    ) {
      setTimeout(() => removeNotification(id), 5000)
    }

    return id
  }

  const removeNotification = (id: string) =>
    setNotifications(prevState =>
      prevState.filter(notification => notification.id !== id)
    )

  const handleError = (error: unknown) => {
    console.error(error)

    const isSessionError = typeof error === 'string' &&
      error.includes('(unauthorized)')

    if (
      isSessionError && notifications.find(n => n.type === 'session-error')
    ) {
      return
    }

    addNotification({
      body: isSessionError
        ? (
          <>
            <p className='mb-1'>
              Ihre Sitzung ist abgelaufen. Bitte sichern Sie Ihre Daten und
              laden die App neu!
            </p>

            <button
              className='bg-white px-2 py-1 rounded text-red-500'
              onClick={() => window.location.reload()}
              type='button'
            >
              App neu laden
            </button>
          </>
        )
        : `Es ist ein Fehler aufgetreten: ${error}`,
      title: 'Fehler',
      type: isSessionError ? 'session-error' : 'error'
    })
  }

  const { data, isFetching, refetch } = useQuery<Session>(
    'session',
    ({ signal }) => fetchSession(signal),
    {
      initialData: initialSession,
      onError: handleError,
      refetchOnWindowFocus: false,
      retry: 0
    }
  )

  const createSession = useMutation((user: Credentials) => saveSession(user), {
    onError: handleError,
    onSuccess: () => refetch()
  })

  const deleteSession = useMutation(() => removeSession(), {
    onError: handleError,
    onSuccess: () => refetch()
  })

  const handleLogin = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const { name, password } = Object.fromEntries([
      ...new FormData(e.currentTarget)
    ])

    createSession.mutate({ name, password })
  }

  const handleLogout = () => {
    deleteSession.mutate()
  }

  useEffect(() => {
    const handler = () => {
      if (offlineRef.current) {
        removeNotification(offlineRef.current)
      }

      offlineRef.current = addNotification({
        body:
          'Ihr Browser kann z.Zt. keine Verbindung mit dem Internet aufbauen.',
        title: 'Offline',
        type: 'warning'
      })
    }

    window.addEventListener('offline', handler)
    return () => window.removeEventListener('offline', handler)
  }, [])

  useEffect(() => {
    const handler = () => {
      if (offlineRef.current) {
        removeNotification(offlineRef.current)
      }

      offlineRef.current = addNotification({
        body:
          'Ihr Browser konnte die Verbindung mit dem Internet wiederherstellen.',
        title: 'Online',
        type: 'success'
      })
    }

    window.addEventListener('online', handler)
    return () => window.removeEventListener('online', handler)
  }, [])

  useEffect(() => {
    let notification: string = ''

    const interval = setInterval(() => {
      if (!navigator.onLine) return
      fetchStatus().then(isUp => {
        if (isUp && notification) {
          removeNotification(notification)
          notification = ''
          addNotification({
            body: 'Das Backend ist nun wieder erreichbar.',
            title: 'Backend-Fehler',
            type: 'success'
          })
        }

        if (!isUp) {
          notification = addNotification({
            body: 'Das Backend ist z.Zt. nicht erreichbar.',
            title: 'Backend-Fehler',
            type: 'warning'
          })
        }
      })
    }, 5 * 60 * 1000)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className='selection:bg-indigo-500 selection:text-white'>
      <div className='min-h-screen'>
        {data?.userCtx.name
          ? (
            <BrowserRouter>
              <nav className='bg-white flex items-center overflow-x-auto p-3 shadow-lg w-full'>
                <div className='mx-2'>
                  <Logo />
                </div>

                <div className='flex w-full'>
                  <ul className='flex items-center w-full'>
                    <li>
                      <NavLink
                        className={({ isActive }) =>
                          isActive
                            ? `${menuItemClasses} pointer-events-none border-indigo-100`
                            : menuItemClasses}
                        to='/documents'
                      >
                        Dokumente
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        className={({ isActive }) =>
                          isActive
                            ? `${menuItemClasses} border-indigo-100`
                            : menuItemClasses}
                        to='/contacts'
                      >
                        Kontakte
                      </NavLink>
                    </li>
                  </ul>

                  <button
                    className={menuItemClasses}
                    disabled={deleteSession.isLoading}
                    onClick={handleLogout}
                    type='button'
                  >
                    Abmelden
                  </button>
                </div>
              </nav>

              <main className='p-4'>
                <Routes>
                  <Route
                    path='/contacts'
                    element={
                      <Contacts
                        handleError={handleError}
                        username={data.userCtx?.name || ''}
                      />
                    }
                  />

                  <Route
                    path='/contacts/:id'
                    element={
                      <Contact
                        handleError={handleError}
                        username={data.userCtx?.name || ''}
                      />
                    }
                  />

                  <Route
                    path='/documents'
                    element={
                      <Documents
                        handleError={handleError}
                        username={data.userCtx?.name || ''}
                      />
                    }
                  />

                  <Route
                    path='/documents/:id'
                    element={
                      <Document
                        addNotification={addNotification}
                        handleError={handleError}
                        username={data.userCtx?.name || ''}
                      />
                    }
                  />

                  <Route
                    path='/'
                    element={<Navigate replace to='/documents' />}
                  />

                  <Route path='*' element={<NotFound />} />
                </Routes>
              </main>
            </BrowserRouter>
          )
          : (
            <Login
              handleLogin={handleLogin}
              isLoading={isFetching || createSession.isLoading}
            />
          )}
      </div>

      <footer className='p-2 text-slate-500'>
        {pkg.name} {process.env.REACT_APP_CURRENT_REV} – &copy;{' '}
        {new Date().getFullYear()}{' '}
        <a href='https://mundpropaganda.net'>MUNDPROPAGANDA.net</a>
      </footer>

      <Notifications
        notifications={notifications}
        removeNotification={removeNotification}
      />
    </div>
  )
}

export default App
