import clsx from 'clsx'
import { filesize } from 'filesize'
import { DragEvent, MouseEvent, SVGAttributes, useState } from 'react'
import { fetchAttachment } from '../api'
import { Document, Upload } from '../types'
import IconFile from './Icons/File'
import IconPdf from './Icons/FilePdf'
import IconImage from './Icons/Image'
import IconPlus from './Icons/Plus'
import IconTrash from './Icons/Trash'

interface AttachmentsProps {
  addFilesizeNotification: () => void
  handleAddUploads: (files: File[]) => void
  handleError: (error: unknown) => void
  handleRemove: (e: MouseEvent<HTMLButtonElement>) => void
  item: Document
  uploads: Upload[]
  username: string
}

interface IconProps extends SVGAttributes<SVGElement> {
  filetype: string
}

const Icon = ({ filetype, ...props }: IconProps) => {
  if (filetype.startsWith('image')) {
    return <IconImage {...props} />
  }

  if (filetype.includes('pdf')) {
    return <IconPdf {...props} />
  }

  return <IconFile {...props} />
}

const Attachments = (
  {
    addFilesizeNotification,
    handleAddUploads,
    handleError,
    handleRemove,
    item,
    uploads,
    username
  }: AttachmentsProps
) => {
  const [dragging, setDragging] = useState(false)

  const handleDownload = (e: MouseEvent<HTMLButtonElement>) => {
    fetchAttachment(username, item._id, e.currentTarget.name).then(
      blob => {
        const url = URL.createObjectURL(blob)
        window.open(url)
      },
      handleError
    )
  }

  const handleDrag = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setDragging(e.type === 'dragover')
  }

  const handleDrop = async (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setDragging(false)

    const files: File[] = []

    for (let file of e.dataTransfer.files) {
      try {
        await file.slice(0, 1).arrayBuffer()
        files.push(file)
      } catch {
        console.debug(`Ignoring directory "${file.name}"`)
      }
    }

    if (files.some(file => file.size > 10 * 1024 ** 2)) {
      return addFilesizeNotification()
    }

    handleAddUploads(files)
  }

  const pickFile = () => {
    const el = document.createElement('input')
    el.setAttribute(
      'accept',
      '.pdf,.jpg,.jpeg,.png,application/pdf,application/x-pdf,image/jpeg,image/png'
    )
    el.setAttribute('multiple', 'true')
    el.setAttribute('type', 'file')
    el.onchange = (e: Event) => {
      const target = e.target as HTMLInputElement
      const files = [...target.files as FileList]

      if (files.some(file => file.size > 10 * 1024 ** 2)) {
        return addFilesizeNotification()
      }

      if (files.length > 0) {
        handleAddUploads(files)
      }
    }
    el.click()
  }

  const files = [...Object.entries(item?._attachments ?? {}), ...uploads]
    .reduce((acc: Upload[], file) => {
      if (Array.isArray(file)) {
        acc.push({
          name: file[0],
          type: file[1].content_type,
          size: file[1].length
        })
        return acc
      }

      file._pending = true

      const i = acc.findIndex(f => f.name === file.name)
      if (i > -1) {
        acc[i] = file
        return acc
      }

      acc.push(file)
      return acc
    }, []).sort((a, b) => a.name.localeCompare(b.name))

  return (
    <>
      <div className='gap-2 flex-wrap inline-flex w-auto'>
        {files.map(file => (
          <div
            className='bg-white border border-slate-200 group h-32 hover:shadow-none p-2 relative rounded shadow transition-shadow w-32'
            key={file.name}
          >
            <button
              className='absolute group-hover:opacity-100 opacity-0 right-1 text-red-500 top-1 transition-opacity'
              name={file.name}
              onClick={handleRemove}
              type='button'
            >
              <IconTrash height='14' width='14' />
            </button>

            <div className='items-center justify-between flex flex-col h-full'>
              <div className='flex h-full'>
                <button
                  name={file.name}
                  onClick={handleDownload}
                  type='button'
                >
                  <Icon
                    className={file._pending
                      ? 'text-slate-200'
                      : 'text-indigo-600'}
                    filetype={file.type}
                    height='64'
                    width='64'
                  />
                </button>
              </div>

              <div className='w-full'>
                <button
                  className='text-slate-600 text-sm truncate w-full'
                  name={file.name}
                  onClick={handleDownload}
                  title={file.name}
                  type='button'
                >
                  {file.name}
                </button>

                <div className='leading-none text-center text-slate-400 text-xs'>
                  {filesize(file.size).toString()}
                </div>
              </div>
            </div>
          </div>
        ))}

        <div
          className={clsx(
            'bg-white border group h-32 hover:shadow-none p-2 relative rounded shadow transition-shadow w-32',
            dragging ? 'border-indigo-600' : 'border-slate-200'
          )}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        >
          <div className='items-center justify-between flex flex-col h-full'>
            <div className='flex h-full'>
              <button onClick={pickFile} type='button'>
                <IconPlus
                  className={dragging
                    ? 'text-indigo-600'
                    : 'text-slate-200'}
                  height='64'
                  width='64'
                />
              </button>
            </div>

            <div className='w-full'>
              <button
                className='text-slate-600 text-sm truncate w-full'
                type='button'
                onClick={pickFile}
              >
                Hinzufügen
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Attachments
