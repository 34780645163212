interface CenterBoxProps {
  children: React.ReactNode
}

const CenterBox = ({ children, ...props }: CenterBoxProps) => (
  <div className='bg-white border-2 border-indigo-600 m-6 lg:w-2/5 md:w-1/2 p-6 rounded shadow-lg w-full'>
    {children}
  </div>
)

export default CenterBox
