import { ChangeEvent, InputHTMLAttributes } from 'react'
import { Option } from '../types'

const classes =
  'block border border-slate-200 outline-indigo-600 focus:shadow-none hover:shadow-none p-2 rounded shadow transition-shadow w-full'

interface InputProps extends InputHTMLAttributes<HTMLSelectElement> {
  isActive?: boolean
  isClearable?: boolean
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void
  options?: Array<string | Option>
  placeholder?: string
}

const Select = (
  {
    isActive = false,
    isClearable = false,
    options = [],
    placeholder,
    ...extraProps
  }: InputProps
) => {
  const _options = [{
    disabled: !isClearable,
    label: placeholder || '(Bitte auswählen…)',
    value: ''
  }, ...options]

  return (
    <select
      className={`${classes}${isActive ? ' border-slate-500' : ''}`}
      style={{ paddingBottom: 9, paddingTop: 10 }}
      {...extraProps}
    >
      {_options.map(option => {
        const disabled = typeof option !== 'string' ? option.disabled : false
        const label = typeof option === 'string' ? option : option.label
        const optValue = typeof option === 'string' ? option : option.value

        return (
          <option
            disabled={disabled}
            key={label}
            value={optValue}
          >
            {label}
          </option>
        )
      })}
    </select>
  )
}

export default Select
