import clsx from 'clsx'
import { FormEvent, useEffect } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchItem, removeItem, saveItem } from '../api'
import Button from '../components/Button'
import H1 from '../components/H1'
import IconLeft from '../components/Icons/Left'
import IconTrash from '../components/Icons/Trash'
import Input from '../components/Input'
import Label from '../components/Label'
import Textarea from '../components/Textarea'
import { contacts as schema } from '../schemas'
import { docTitle, getFormData } from '../utils'

interface ContactProps {
  handleError: (error: unknown) => void
  username: string
}

const Contact = ({ handleError, username }: ContactProps) => {
  const navigate = useNavigate()
  const { id } = useParams()

  const { data, error, isLoading } = useQuery(
    ['contacts', id],
    ({ signal }) => fetchItem(username, 'contacts')(id, signal),
    { onError: handleError }
  )

  const remove = useMutation(data => removeItem(username, data))
  const upsert = useMutation(data => saveItem(username, 'contacts', data))

  const title = `${schema.label.singular} ${
    id === 'new' ? 'erstellen' : 'bearbeiten'
  }`

  const handleDelete = () => {
    if (window.confirm(`${data.name} wirklich löschen?`)) {
      remove.mutate(data, {
        onError: handleError,
        onSuccess: () => navigate(-1)
      })
    }
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const changes = getFormData(e.currentTarget, schema)
    upsert.mutate(
      { ...data, ...changes },
      {
        onError: handleError,
        onSuccess: () => navigate(-1)
      }
    )
  }

  useEffect(() => {
    docTitle(title)
  }, [])

  return (
    <>
      <div className='flex items-center gap-2'>
        <button
          className='pb-4 text-indigo-600'
          onClick={() => navigate(-1)}
          type='button'
        >
          <IconLeft height='22' width='22' />
        </button>

        <H1>{title}</H1>
      </div>

      <form
        className={clsx(
          (isLoading || remove.isLoading || upsert.isLoading) &&
            'opacity-50 pointer-events-none'
        )}
        onSubmit={handleSubmit}
      >
        <div className='flex flex-wrap -m-2'>
          {schema.fields.map(({ defaultValue, label, type, ...props }) => (
            <div className='md:w-1/2 p-2 w-full' key={props.name}>
              <Label>
                {label}
                {type === 'textarea'
                  ? (
                    <Textarea
                      defaultValue={data?.[props.name]}
                      key={data?.[props.name]}
                      {...props}
                    />
                  )
                  : (
                    <Input
                      defaultValue={data?.[props.name]}
                      key={data?.[props.name]}
                      type={type}
                      {...props}
                    />
                  )}
              </Label>
            </div>
          ))}
        </div>

        <div className='flex justify-end pt-6'>
          <div className='flex gap-4'>
            {id !== 'new' && !error && (
              <div>
                <Button isWhite onClick={handleDelete} type='button'>
                  <div className='flex gap-1 items-center'>
                    <div>
                      <IconTrash className='block fill-red-500' />
                    </div>

                    <div>Löschen</div>
                  </div>
                </Button>
              </div>
            )}

            <div>
              <Button color='primary' disabled={!!error} type='submit'>
                Speichern
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default Contact
