import { ChangeEvent } from 'react'
import { useQuery } from 'react-query'
import { fetchAll } from '../api'
import schemas from '../schemas'
import Select from './Select'

interface SelectFromServiceProps {
  defaultValue?: string
  handleError: (error: unknown) => void
  isActive?: boolean
  isClearable?: boolean
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void
  placeholder?: string
  service: string
  username: string
  value?: string
}

const SelectFromService = (
  { handleError, service, username, ...props }: SelectFromServiceProps
) => {
  const schema = schemas[service as keyof typeof schemas]

  const query = {
    direction: schema.sort.direction,
    limit: 999,
    orderby: schema.sort.orderby,
    search: '',
    skip: 0
  }

  const { data, isLoading } = useQuery(
    [service, query],
    ({ signal }) =>
      fetchAll(username, service as keyof typeof schemas)(query, signal),
    {
      onError: handleError,
      retry: 0
    }
  )

  return (
    <Select
      options={isLoading
        ? [{ disabled: true, label: 'Lade…', value: '' }]
        : data.docs.map((doc: any) => ({
          label: schema.getLabel(doc),
          value: doc._id
        }))}
      {...props}
    />
  )
}

export default SelectFromService
